<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('lead.wizard_lead.title') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="list"></octo-icon>
            <p>{{ $t('lead.wizard_lead.registry_choice') }}</p>
          </template>
          <lead-wizard-first-step ref="leadWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="list"/>
            <p>{{ $t('lead.wizard_lead.course_and_campaign_choice') }}</p>
          </template>
          <lead-wizard-second-step ref="leadWizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="sync"/>
            <p>{{ $t('lead.wizard_lead.status') }}</p>
          </template>
          <lead-wizard-third-step ref="leadWizardThirdStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(4)">
          <template slot="label">
            <octo-icon icon="statistics"/>
            <p>{{ $t('lead.wizard_lead.report') }}</p>
          </template>
          <lead-wizard-fourth-step
            ref="leadWizardFourthStep"
            :key="reportKey"
            :courses="courses"
            :registry="registry"
            :campaign="campaign"
            :operator-id="operatorId"
            :status="status"
            :deadline="deadline"
            :notes="notes"
          />
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Registry from "@/models/registry";
import LeadWizardFirstStep from "@/pages/Leads/lead-wizard/LeadWizardFirstStep";
import LeadWizardSecondStep from "@/pages/Leads/lead-wizard/LeadWizardSecondStep";
import LeadWizardThirdStep from "@/pages/Leads/lead-wizard/LeadWizardThirdStep";
import LeadWizardFourthStep from "@/pages/Leads/lead-wizard/LeadWizardFourthStep";
import {endpoints} from "@/routes/endpoints";
import Campaign from "@/models/campaign";
import {MessageBox} from "element-ui";

export default {
  name: "WizardPage",
  components: {
    LeadWizardThirdStep,
    LeadWizardFourthStep,
    LeadWizardSecondStep,
    LeadWizardFirstStep,
    OctoIcon,
    WizardTab,
    SimpleWizard,
  },
  data() {
    return {
      registry: this.$_.cloneDeep(Registry),
      campaign: this.$_.cloneDeep(Campaign),
      courses: [],
      reportKey: 0,
      status: '',
      deadline: '',
      notes: '',
      operatorId: null
    }
  },
  methods: {
    async validateStep(step) {
      let check = false;
      let data;
      switch (step) {
        case 1:
          const registry = this.$refs.leadWizardFirstStep.getSelectedRegistry();
          if (registry) {
            this.registry = registry;
            this.reportKey++;
            check = true;
          }
          break;
        case 2:
          data = await this.$refs.leadWizardSecondStep.validate();
          if (data) {
            this.courses = data.courses;
            this.campaign = data.campaign;
            this.reportKey++;
            check = true;
          }
          break;
        case 3:
          data = await this.$refs.leadWizardThirdStep.validate();
          data.notes = this.$refs.leadWizardThirdStep.getLeadNotes();
          if (data) {
            this.status = data.status;
            this.operatorId = data.operatorId;
            this.deadline = data.deadline;
            this.notes = data.notes;
            this.reportKey++;
            check = true;
          }
          break;
        case 4:
          check = true;
          break;
        default:
          check = false;
          break;
      }
      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    completeWizard() {
      this.$api.post(
        endpoints.CALL_CENTER_LEAD_FROM_REGISTRY
          .replace('{registryId}', this.registry.id)
          .replace('{campaignId}', this.campaign.id),
        {
          status: this.status,
          operatorId: this.operatorId,
          deadline: this.deadline,
          notes: this.notes,
          courseIds: this.$_.map(this.courses, course => {
            return course.id;
          }),
        }
      )
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('lead.wizard_lead.back_on_leads_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'leads.in_progress'})
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
