<template>
  <ValidationObserver ref="secondStep">
    <div class="container-fluid">
      <div class="row justify-content-center mb-3">
        <div class="col-auto text-center">
          <p>{{ $t('lead.wizard_lead.second_step_title') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ValidationProvider name="campaign" rules="required" v-slot="{ passed, failed, errors }">
            <base-input
              required
              :label="$t('fields.campaign')"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-select
                class="select-default text-uppercase"
                filterable
                value-key="id"
                :placeholder="$t('fields.type_to_search')"
                v-model="campaign"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(campaign, index) in campaigns"
                  :value="campaign"
                  :label="campaign.name"
                  :key="`campaign-${index}`"
                >
                </el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>
      </div>
      <course-selector ref="courseSelector"/>
    </div>
  </ValidationObserver>
</template>

<script>

import {mapGetters} from "vuex";
import {Option, Select} from "element-ui";
import CourseSelector from "@/pages/Courses/components/CourseSelector";

export default {
  name: "LeadWizardSecondStep",
  components: {CourseSelector, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      campaign: null,
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.secondStep.validate()
          .then((success) => {
            const courses = this.$refs.courseSelector.getSelectedCourses();

            if (success && courses.length > 0) {
              resolve({
                campaign: this.campaign,
                courses: courses
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  computed: {
    ...mapGetters({
      campaigns: 'common/campaigns',
    })
  }
}
</script>

<style scoped>

</style>
