<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('lead.wizard_lead.third_step_title') }}</p>
        <p>{{ $t('lead.wizard_lead.third_step_subtitle') }}</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-8">
        <assign-call-center-lead-modal
          ref="assignCallCenLeadModal"
          :statuses="statuses"
        />
      </div>
    </div>
    <div class="row mt-2 d-flex justify-content-center">
      <div class="col-8">
        <base-text-area :label="$t('fields.notes')" v-model="notes"/>
      </div>
    </div>
  </div>
</template>

<script>

import AssignCallCenterLeadModal from "../components/AssignCallCenterLeadModal";
import CallCenterLeadStatuses from "../../CallCenter/resources/callCenterLeadStatuses";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "LeadWizardThirdStep",
  components: {BaseTextArea, AssignCallCenterLeadModal},
  data() {
    return {
      notes: '',
      statuses: [
        CallCenterLeadStatuses.free.value,
        CallCenterLeadStatuses.public.value,
        CallCenterLeadStatuses.private.value
      ]
    }
  },
  methods: {
    validate() {
      return this.$refs.assignCallCenLeadModal.validate();
    },

    getLeadNotes() {
      return this.notes;
    }
  }
}
</script>

<style scoped>

</style>
