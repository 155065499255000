<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('lead.wizard_lead.fourth_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <registry-personal-data :editable="false" :registry="registry"/>
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.notes') }}</h4>
          </div>
          <p>{{ notes }}</p>
        </card>
      </div>
      <div class="col-12 col-md-6">
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('callcenter.lead_data') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-if="status"
              class="text-capitalize"
              :label="$t('fields.status')"
              :value="$t('callcenter.status.' + status)"
            />
            <list-group-item-component
              v-if="deadline"
              :label="$t('fields.appointment')"
              :value="deadline | datetime"
            />
            <list-group-item-component v-if="operatorId" :label="$t('fields.operator')">
              <template slot="value">
                <label-theme-component>
                  {{ users[operatorId] | optional('lastname') }}
                  {{ users[operatorId] | optional('firstname') }}
                </label-theme-component>
              </template>
            </list-group-item-component>
          </ul>
        </card>
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.courses') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <li class="standard-li"
                v-for="(course, index) in courses"
                v-bind:key="index">
              {{course.title}}
            </li>
          </ul>
        </card>
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.campaign') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <li class="standard-li">
              {{ campaign.name }}
            </li>
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import Registry from "@/models/registry";
import Campaign from "@/models/campaign";
import RegistryPersonalData from "../../Registries/components/RegistryPersonalData";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";

export default {
  name: "LeadWizardFourthStep",
  components: {LabelThemeComponent, ListGroupItemComponent, RegistryPersonalData},
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
    registry: {
      type: Object,
      default: () => Registry
    },
    campaign: {
      type: Object,
      default: () => Campaign
    },
    status: {
      default: ''
    },
    operatorId: {
      default: null
    },
    deadline: {
      default: null
    },
    notes: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  }
}
</script>

<style scoped>

</style>
