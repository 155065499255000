<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <base-input :label="$t('fields.course')">
        <el-select
          class="select-default text-uppercase"
          multiple
          filterable
          value-key="code"
          collapse-tags
          :placeholder="$t('fields.type_to_search')"
          v-model="selectedCourses"
        >
          <el-option
            class="select-default text-uppercase"
            v-for="(course, index) in courses"
            :value="course"
            :label="course.title"
            :key="`course-${index}`"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-input :label="$t('fields.certificates')">
        <el-select
          class="select-default text-uppercase"
          multiple
          filterable
          value-key="code"
          collapse-tags
          :placeholder="$t('fields.type_to_search')"
          v-model="selectedCertificates"
        >
          <el-option
            class="select-default text-uppercase"
            v-for="(certificate, index) in certificates"
            :value="certificate"
            :label="certificate.title"
            :key="`certificate-${index}`"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-input :label="$t('fields.workshops')">
        <el-select
          class="select-default text-uppercase"
          multiple
          filterable
          value-key="code"
          collapse-tags
          :placeholder="$t('fields.type_to_search')"
          v-model="selectedWorkshops"
        >
          <el-option
            class="select-default text-uppercase"
            v-for="(workshop, index) in workshops"
            :value="workshop"
            :label="workshop.title"
            :key="`workshop-${index}`"
          >
          </el-option>
        </el-select>
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush mt-3">
        <list-group-item-component
          v-for="(entity, index) in selectedEntities"
          v-bind:key="`entity-${index}`"
          :label="entity.title"
        >
          <template slot="value">
            <base-button icon link size="sm" class="text-capitalize m-0" @click="deleteEntity(entity)">
              <octo-icon icon="wrong"/>
            </base-button>
          </template>
        </list-group-item-component>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Option, Select} from "element-ui";

export default {
  name: "CourseSelector",
  components: {OctoIcon, ListGroupItemComponent, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      selectedCourses: [],
      selectedCertificates: [],
      selectedWorkshops: [],
    }
  },
  methods: {
    getSelectedCourses() {
      return this.$_.cloneDeep(this.selectedEntities);
    },

    deleteEntity(entity) {
      switch (entity.type) {
        case 'certificate':
          this.selectedCertificates.splice(
            this.$_.findIndex(this.selectedCertificates, certificate => certificate.id === entity.id),
            1
          );
          break;
        case 'course':
          this.selectedCourses.splice(
            this.$_.findIndex(this.selectedCourses, course => course.id === entity.id),
            1
          );
          break;
        case 'workshop':
          this.selectedWorkshops.splice(
            this.$_.findIndex(this.selectedWorkshops, workshop => workshop.id === entity.id),
            1
          );
          break;
      }
    }
  },
  computed: {
    ...mapGetters({
      certificates: 'common/certificates',
      workshops: 'common/workshops',
      courses: 'common/courses',
    }),

    selectedEntities() {
      return this.$_.union(
        this.selectedCourses,
        this.selectedCertificates,
        this.selectedWorkshops,
      )
    }
  }
}
</script>

<style scoped>

</style>
